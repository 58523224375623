import React from "react";
import { graphql } from "gatsby";

import { OurStoryPageTemplate } from "../templates";
import { Layout } from "../components";

const OurStoryPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout title={frontmatter.headerTitle}>
      <OurStoryPageTemplate {...frontmatter} />
    </Layout>
  );
};

export default OurStoryPage;

export const pageQuery = graphql`
  query OurStoryPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "our-story-page" } }) {
      frontmatter {
        headerImage {
          cloudinaryMedia
          assetType
          deliveryType
          urlRemainder
          sourceURL
        }
        headerTitle
        testimonialTitle
        testimonials {
          company
          companyLogo {
            cloudinaryMedia
            assetType
            deliveryType
            urlRemainder
            sourceURL
          }
          clientName
          body
        }
        clientLogo {
          logo {
            cloudinaryMedia
            assetType
            deliveryType
            urlRemainder
            sourceURL
          }
        }
        heroTitle
        heroBody
        continueTitle
        continueBody
        conclusionImage {
          cloudinaryMedia
          assetType
          deliveryType
          urlRemainder
          sourceURL
        }
        conclusionTitle
        conclusionBody
      }
    }
  }
`;
